import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`El capell d’aquest bolet el podem trobar hemisfèric, convex i obert i estès depenent de l’edat, però sempre de color marronós i viscós quan hi ha humitat. Pot fer fins a 8 cm de diàmetre. Les làmines són blanques, lliures i atapeïdes i el peu presenta dues coloracions, blanquinós sobre l’anell i més clar per davall. El peu és més llarg que el diàmetre del capell i l’anell és membranós i perdura molt de temps. Les espores són blanques en massa, de globoses a subgloboses, de 5-8 x 4,5-6,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      